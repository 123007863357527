export const environment = {
  production: true,
  BASE_URL: 'https://www.menute.be',
  BASE_URL_NL: 'https://www.menute.nl',
  API_KEY: 'AIzaSyDpoWh7K4MhE9MM2YwRGbbAsENTD8NcOKw',
  ZIP_CODE_PATTERN: "^[1-9]{1}[0-9]{3}$",
  CHECKOUT_URL:"https://checkout.menute.be",
  CHECKOUT_URL_NL:"https://checkout.menute.nl",
  CDN_URl:'https://cdn.etenonline.be/',
  CAT_PATH:'upload/categories/',
  MENU_PATH:'upload/menus/',
  version: '1.0.0'
};
