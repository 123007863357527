import { Component, OnInit, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { BsModalService  } from 'ngx-bootstrap/modal';
import { RestaurantFactory } from '../../shared/models/restaurantFactory';
import { Restaurant } from '../../shared/models/restaurant.model';
import { environment } from '../../../environments/environment.prod';
import { BasketFactory } from '../../shared/models/basketFactory';
import { DeliverZipCode } from '../../shared/models/deliverZipCode.model';
import { DataService } from '../../shared/services/data.service';
import { SharedDataService } from '../../shared/services/shared-data.service';

@Component({
  selector: 'app-zip-code-modal',
  templateUrl: './zip-code-modal.component.html',
  styleUrls: ['./zip-code-modal.component.css']
})
export class ZipCodeModalComponent implements OnInit {

  public tld='be';
  public restaurant: Restaurant = this._resFactory.restaurant;
  public zipCodePattern: string = environment.ZIP_CODE_PATTERN;
  //----------------------------------------------------------
  public isDeliveringToUsersZipCode: boolean = true; // to show the hidden div under the modal -> to let the user select a delivery method

  public isDeliveringToday: boolean = true; // restaurant is delivering today?

  public rdbPickupChecked: boolean = false; // default selection of delivery method => only used if restaurant does not deliver to user location

  public userInputZipCode: string = ""; // user input -> the zipcode of the user

  public restaurantDeliverZipCodes: DeliverZipCode[] = this._resFactory.deliverZipCodes; // the zipcodes where the restaurant will deliver

  public enteredUserZipCode: string = this._basketFactory.userZipCode; // the saved zipcode of the user

  public isSubmittedSuccesfully: EventEmitter<boolean> = new EventEmitter(); // to let the parent element know that the form is submitted succesfully

  @ViewChild('inputUserZipCode', { static: true }) private elementInputUserZipCode: ElementRef;

  constructor(public activeModal: BsModalService, private _resFactory: RestaurantFactory, private _basketFactory: BasketFactory, private _dataService: DataService, private _sharedDataService: SharedDataService) {

    if(this._sharedDataService.baseurl.endsWith(".nl")){
      this.tld = 'nl';
    }

  }

  ngOnInit() {
    this.sendEventToGoogleAnalytics("zipv","");

    // if (this._resFactory.deliverZipCodes == []) {
    //   this.getDeliverZipCodes();
    // }
  }

  ngAfterViewInit() {
    setTimeout(()=>{
    this.elementInputUserZipCode.nativeElement.focus();
    },1);
  }

  public closeModal() {
    this.activeModal?.hide('zip');
  }

  public submitZipCode(userInput: string) {
    this.sendEventToGoogleAnalytics("srch",userInput);
    if (this.isRestaurantDeliveringToday()) {
      if (this.rdbPickupChecked) {
        this.sendEventToGoogleAnalytics("pickup",userInput);
        this._basketFactory.userSelectedPickupMethod = true;
        this.isDeliveringToUsersZipCode = true;
        localStorage.setItem('pickup', 'true');
        sessionStorage.setItem('zip', userInput);
        this.isSubmittedSuccesfully.next(true);
        this.closeModal();
      } else {
        localStorage.setItem('pickup', 'false');
        if (this.validateZipCode(userInput)) {
          this._basketFactory.userSelectedPickupMethod = false;
          this.enteredUserZipCode = userInput;
          sessionStorage.setItem('zip', this.enteredUserZipCode);
          this._basketFactory.userZipCode = userInput;

          let index = this.restaurantDeliverZipCodes.findIndex(x => x.zcZip == userInput);
          if (index < 0) {
            this.sendEventToGoogleAnalytics("errsrch",userInput);
            this.isDeliveringToUsersZipCode = false;
          } else {
            this.sendEventToGoogleAnalytics("succ",userInput);
            this.isDeliveringToUsersZipCode = true;
            this.isSubmittedSuccesfully.next(true);
            this.closeModal();
          }
        }
        else
        {
          this.sendEventToGoogleAnalytics("errsrchval",userInput);
        }
      }
    } else {
      this.sendEventToGoogleAnalytics("onlypick","");
      this.isDeliveringToUsersZipCode = false;
      this.isDeliveringToday = false;
      this._basketFactory.userSelectedPickupMethod = true;

      if (this.rdbPickupChecked) {
        localStorage.setItem('pickup', 'true');
        sessionStorage.setItem('zip', "");
        this.isSubmittedSuccesfully.next(true);
        this.closeModal();
      }
      this.rdbPickupChecked = true;
    }
  }

  private isRestaurantDeliveringToday(): boolean {
    if (this.restaurant.isDeliveringToday == 0) {
      return false;
    } else {
      return true;
    }
  }

  private validateZipCode(zipCode: string): boolean {
    const regexp = new RegExp('^[1-9]{1}[0-9]{3}$');
    return regexp.test(zipCode);
  }

  // private getDeliverZipCodes() {

  //   this._dataService.getRestaurantDeliverZipCodes(this._resFactory.obtainedResId).subscribe(res => {
  //     this.restaurantDeliverZipCodes = res;
  //     this._resFactory.deliverZipCodes = res;
  //     // console.log(this.restaurantDeliverZipCodes);
  //   })
  // }

  private sendEventToGoogleAnalytics(act,act2: string) {
    let gaString: string = act + "_" + this.restaurant.restaurant_name + "_"+act2;

    this._sharedDataService.sendGoogleAnalyticsEvent(gaString, this.restaurant.id.toString(), "", -1);
  }

}
