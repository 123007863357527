// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  BASE_URL: 'https://dev.menute.be',
  BASE_URL_NL:'https://dev.menute.nl',
  API_KEY: 'AIzaSyDpoWh7K4MhE9MM2YwRGbbAsENTD8NcOKw',
  ZIP_CODE_PATTERN: "^[1-9]{1}[0-9]{3}$",
  CHECKOUT_URL:"https://devcheckout.menute.be",
  CHECKOUT_URL_NL:"https://devcheckout.menute.nl",
  CDN_URl:'https://devcdn.etenonline.be/',
  CAT_PATH:'upload/categories/',
  MENU_PATH:'upload/menus/',
  version: '1.0.0'
};
