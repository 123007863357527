import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
// import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';


import { map } from "rxjs/operators";
//import 'rxjs/add/operator/map';
//import { Restaurant } from '../models/restaurant.model';
import { HttpXService } from './httpx.service';
import { BasketModel } from '../models/basket.model';
import { Order, OrderData } from '../models/order.model';
import { SharedDataService } from './shared-data.service';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Accept': 'application/json',
    'X-P1' : environment.version
  })
};

@Injectable()
export class DataService {


  protected endpoint_checkout:string = "/xHttp/";
  protected endpoint_api_xhttp:string = "/api/xHttp/";

  constructor(private http: HttpXService, private _sharedDataService: SharedDataService) {
  }

  public getRestaurantInfo(restid: number): any {
    //let endpoint: string = "";
    const endpoint: string = this.endpoint_api_xhttp + 'subweb?id=' + restid + '';

    return this.http.getOptions(this._sharedDataService.baseurl + endpoint, httpOptions)
      .pipe(map((res: any) => res));
  }

  // public getRestaurantInfoNode(restid: number): any {
  //   //let endpoint: string = "";
  //   const endpoint: string = this.endpoint_checkout + 'subweb?id=' + restid + '&token=deneme';

  //   return this.http.getOptions(this.BASE_URL + endpoint, httpOptions)
  //     .pipe(map((res: any) => res));
  // }

  // public getMenuDetailOptions(productId: string, menucat: string, restid: number): any {
  //   const endpoint: string = this.endpoint_api_xhttp + "subweb_showsidedishes";

  //   const body = {
  //     'action': "add",
  //     'product': productId,
  //     'menucat': menucat,
  //     'rest': restid.toString(),
  //     'domid': "productform" + productId
  //   }

  //   return this.http.postOptions(this._sharedDataService.baseurl + endpoint,body,httpOptions)
  //     .pipe(map((res: any) => res));
  // }

  public getOrderMetaData(guid: string, paymentid: string, payerid: string, payment_intent: string): any {
   
    const requestbody = {
      "action": "readinfo",
      "guid": guid,
      "paymentid": paymentid,
      "payerid": payerid,
      "payment_intent":payment_intent
    }

    return this.http.postOptions(this._sharedDataService.checkouturl + this.endpoint_checkout, requestbody, httpOptions)
      .pipe(map((res: any) => res));
  }

  // public getCoordinates(street: string, no: string, zip: string, city: string) {
  //   const url: string = "https://maps.googleapis.com/maps/api/geocode/json?address=" + no + "+" + street + "+" + zip + "+" + city + "+belgie&key=" + environment.API_KEY;

  //   return this.http.get(url)
  //     .pipe(map((res: Response) => res.json()));
  // }

  // public getRestaurantDeliverZipCodes(restid: number): any {
  //   //let url: string = "";
    
  //   // console.log("xhttpcallzipcodes");
    
  //   const url: string = this.BASE_URL + this.endpoint_checkout + "subweb_zipcodes";

  //   return this.http.postOptions(url,{"id":restid},httpOptions)
  //     .pipe(map((res: any) => res.json()));

  // }

  public placeOrder(basketList: BasketModel[], order: Order) {
    const orderData: OrderData = new OrderData();

    order.action = "create";
    order.data = orderData.mapBasketModelToOrderDataModel(basketList);

    return this.http.postOptions(this._sharedDataService.checkouturl + this.endpoint_checkout, order, httpOptions)
      .pipe(map((res: any) => res));
  }

  // private getRequestOptions(): RequestOptions {
  //   const headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   headers.append('Accept', 'application/json');

  //   return new RequestOptions({ headers: headers });
  // }

  

  public getRestaurantReviews(restid: number,page: number): any {
    //let endpoint: string = "";
    const endpoint: string = this.endpoint_api_xhttp + 'restaurants/' + restid + '/reviews/'+page;

    return this.http.getOptions(this._sharedDataService.baseurl + endpoint, httpOptions,false)
      .pipe(map((res: any) => res));
  }

  public doErrorLog(data: any): any {

    data.device = window.navigator.userAgent;
    data.url = window.location.href;

    const endpoint: string =  '/api/logms';

    let body = {
      data
    };

    return this.http.postOptions(this._sharedDataService.baseurl + endpoint, body, httpOptions)
      .pipe(map((res: any) => res));
  }

}
